import '../../../theme/datepicker.css'

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { enUS, it } from 'date-fns/esm/locale'
import React, { memo, useEffect, useRef, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import NumericInput from 'react-numeric-input'

registerLocale('en', enUS)
registerLocale('it', it)

export interface Props {
  IBEID?: number
  languageCode: string
  languagePrefix: string
}

export const Syncrobox = memo(function Syncrobox({
  IBEID = 1,
  languageCode,
  languagePrefix,
}: Props) {
  const [linkerParam, setlinkerParam] = useState()

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState<any>(
    new Date(new Date().setDate(startDate.getDate() + 1)),
  )

  const guestsWrap = useRef(null)
  const [guestsWrapStatus, setGuestsWrapStatus] = useState(false)

  const [guestsTotal, setGuestsTotal] = useState('')
  const [adultsValue, setAdultsValue] = useState(2)
  const [childsValue, setChildsValue] = useState(0)

  const adultsLabel = useVocabularyData('adults', languageCode)
  const childsLabel = useVocabularyData('childs', languageCode)

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (guestsWrap.current && !guestsWrap.current.contains(e.target)) {
      setGuestsWrapStatus(false)
    } else {
      if (guestsWrapStatus && e.target.classList.contains('guests-button')) {
        setGuestsWrapStatus(false)
      } else {
        setGuestsWrapStatus(true)
      }
    }
  }

  useEffect(() => {
    const adults = adultsValue > 0 ? `${adultsValue} ${adultsLabel}` : ''
    const childs = childsValue > 0 ? `, ${childsValue} ${childsLabel}` : ''

    setGuestsTotal(`${adults}${childs}`)

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [guestsWrapStatus, adultsValue, childsValue])

  return (
    <Container>
      <Form
        action="https://be.bookingexpert.it/book/simple/step2"
        target="_blank"
      >
        <input type="hidden" name="hotel" value={IBEID} />
        <input type="hidden" name="lang" value={languagePrefix} />
        <Field>
          <Label>{useVocabularyData('arrival', languageCode)}</Label>
          <DatePicker
            autoComplete="off"
            dateFormat="dd-MM-yyyy"
            locale={languagePrefix || 'en'}
            onChange={(date: any) => {
              setStartDate(date)
              setEndDate(new Date(date.getTime() + 1 * 24 * 60 * 60 * 1000))
            }}
            minDate={new Date()}
            name="checkin"
            selected={startDate}
          />
        </Field>
        <Field>
          <Label>{useVocabularyData('departure', languageCode)}</Label>
          <DatePicker
            autoComplete="off"
            dateFormat="dd-MM-yyyy"
            locale={languagePrefix || 'en'}
            onChange={(date: any) => {
              setEndDate(date)
            }}
            minDate={new Date(startDate?.getTime() + 1 * 24 * 60 * 60 * 1000)}
            name="checkout"
            selected={endDate}
          />
        </Field>
        <Guests ref={guestsWrap}>
          <Field className="full-width">
            <Label>{useVocabularyData('guests', languageCode)}</Label>
            <GuestsValue
              className={`guests-button${guestsWrapStatus ? ' open' : ''}`}
            >
              {guestsTotal}
            </GuestsValue>
          </Field>
          <GuestsWrap
            className={guestsWrapStatus ? 'open' : undefined}
            row
            space="between"
            wrap
          >
            <Field>
              <Label>{adultsLabel}</Label>
              <NumericInput
                name="guesttypes[0][21273]"
                min={1}
                max={5}
                style={false}
                value={adultsValue}
                onChange={(num: any) => {
                  setAdultsValue(num)
                }}
              />
            </Field>
            <Field>
              <Label>{childsLabel}</Label>
              <NumericInput
                name="guesttypes[0][21274]"
                min={0}
                max={4}
                style={false}
                value={childsValue}
                onChange={(num: any) => {
                  setChildsValue(num)
                }}
              />
            </Field>
          </GuestsWrap>
        </Guests>
        <Field className="full-width">
          <Coupon
            type="text"
            name="coupon"
            autoComplete="off"
            placeholder={useVocabularyData('promo-code', languageCode)}
          />
        </Field>
        <Submit
          type="submit"
          name="search"
          value={useVocabularyData('check-availability', languageCode)}
        />
        <ChangeReservation
          aria-label="Booking Engine"
          href={`https://be.bookingexpert.it/book/manage/login?dologout=true&hotel=${IBEID}&lang=${languagePrefix}`}
          rel="noreferrer"
          target="_blank"
        >
          {useVocabularyData('change-your-reservation', languageCode)}
        </ChangeReservation>

        <input type="hidden" name="_ga" value={linkerParam} />
      </Form>
    </Container>
  )
})
const Container = styled.section`
  max-width: 480px;
`

const Form = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`

const Field = styled.div`
  width: calc(50% - 30px);
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  margin-top: 2.25rem;
  position: relative;
  &.full-width {
    width: 100%;
    &:before {
      display: none;
    }
  }

  .react-datepicker {
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    background-color: ${({ theme }) => theme.colors.variants.neutralLight3};
    color: ${({ theme }) => theme.colors.variants.neutralDark1};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day:hover,
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.variants.primaryDark1};
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  .react-datepicker__day:focus {
    background-color: ${({ theme }) =>
      theme.colors.variants.primaryDark1} !important;
    color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
  }

  .react-datepicker__day--today {
    &:after {
      background: ${({ theme }) => theme.colors.variants.primaryLight1};
    }
  }

  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.colors.variants.neutralLight1} !important;
  }

  .react-datepicker-wrapper {
    input {
      background: url('/calendar.svg') no-repeat right center;
      border-bottom: 2px solid
        ${({ theme }) => theme.colors.variants.neutralLight3};
      caret-color: transparent;
      font-size: 1.875rem;
      font-weight: 600;
      line-height: 2.5rem;
      padding-bottom: 0.75rem;
    }
  }
  .react-numeric-input {
    position: relative;
    input {
      width: 170px;
      caret-color: transparent;
      font-size: 2.25rem;
      font-weight: 600;
      line-height: 3rem;
      pointer-events: none;
    }
    b {
      width: 1.5rem;
      height: 1.5rem;
      line-height: 1.625rem;
      border: 2px solid ${({ theme }) => theme.colors.variants.neutralLight2};
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      bottom: -0.75rem;
      right: 0;
      text-align: center;
      transition: 0.1s ease-out;
      &:hover {
        background: ${({ theme }) => theme.colors.variants.neutralLight2};
      }
      &:before,
      &:after {
        content: '';
        background: ${({ theme }) => theme.colors.variants.neutralDark1};
        position: absolute;
      }
      &:nth-of-type(1) {
        bottom: 1.4375rem;
        &:before {
          width: 0.625rem;
          height: 0.125rem;
          top: 0.5625rem;
          left: 0.3125rem;
        }
        &:after {
          width: 0.125rem;
          height: 0.625rem;
          top: 0.3125rem;
          left: 0.5625rem;
        }
      }
      &:last-of-type {
        &:before {
          width: 0.625rem;
          height: 0.125rem;
          top: 0.5625rem;
          left: 0.3125rem;
        }
      }
    }
  }
`

const Guests = styled.div`
  width: 100%;
  position: relative;
`

const GuestsValue = styled.div`
  cursor: pointer;
  background: url('/chevron-down-dark.svg') no-repeat right center;
  border-bottom: 2px solid ${({ theme }) => theme.colors.variants.neutralLight3};
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.5rem;
  overflow: hidden;
  padding-right: 2.25rem;
  padding-bottom: 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.open {
    background: url('/chevron-up-dark.svg') no-repeat right center;
  }
`

const GuestsWrap = styled(FlexBox)`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  opacity: 0;
  padding: 0 0 1.875rem 1.875rem;
  position: absolute;
  top: calc(100% - 0.125rem);
  left: 0;
  visibility: hidden;
  z-index: -1;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 3;
  }
  > div {
    margin-top: 1.875rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.0437rem;
  line-height: 0.9375rem;
  margin-bottom: 0.375rem;
  text-transform: uppercase;
`

const Coupon = styled.input`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  height: 3.0625rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.variants.neutralLight3};
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  letter-spacing: 0.0437rem;
  line-height: 0.9375rem;
  text-transform: uppercase;

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.variants.primaryDark1};
  }
  &::-moz-placeholder {
    color: ${({ theme }) => theme.colors.variants.primaryDark1};
    opacity: 1;
  }
  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.variants.primaryDark1};
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.variants.primaryDark1};
  }
`

const Submit = styled.input`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  height: 3.75rem;
  background: ${({ theme }) => theme.colors.variants.primaryDark1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 0.125rem;
  margin-top: 1.875rem;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.primaryDark1};
  }
`

const ChangeReservation = styled.a`
  display: inline-block;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 1.875rem auto 0;
  text-align: center;
  &:hover {
    text-decoration: underline;
  }
`
